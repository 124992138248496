.navbar .navbar-brand {
  margin-left: 3rem;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.navbar .nav-item .dropdown-toggle.nav-link {
  color: #fff !important;
}

.navbar .nav-item .dropdown-menu a {
  color: rgb(0, 0, 0, 0.7);
}

.navbar .dropdown-menu .navbar-dropdown-item {
  display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.navbar.wfi-background-color-1 {
  transition: background-color 1s ease 0s;
  box-shadow: 0 0 4px grey;
}

.navbar.transparent {
  background-color: transparent;
  transition: background-color 1s ease 0s;
}

@media screen and (max-width: 768px) {
  #wfi-navbar .navbar-brand {
    margin: auto;
  }
}
