html {
  height: 100%;
}

body {
  background-color: whitesmoke;
  margin: 0;
  font-family: "Sriracha", cursive;
  font-family: "Yanone Kaffeesatz", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 1.1em;
  overflow-x: hidden;
}

@media screen and (max-width: 585px) {
  body::-webkit-scrollbar {
    width: 2px;
  }
}
