.member-card {
  height: 140px;
  margin: auto;
  width: 140px;
}

.member-card .member-photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25rem;
  height: inherit;
  width: inherit;
}
