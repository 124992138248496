.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flex-around {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.flex-between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.flex-start {
  align-items: flex-start;
  display: flex;
  justify-content: center;
}

.flex-end {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}

.flex-row {
  flex-direction: row;
}

.flex-row {
  flex-direction: column;
}
