:root {
  --color1: #03466f;
  --color2: rgba(9, 89, 174);
  --color3: #02324f;
}

.wfi-background-color-1 {
  background-color: var(--color1);
}

.wfi-background-color-2 {
  background-color: rgba(9, 89, 174, 0.1);
}

.wfi-color-1 {
  color: #03466f;
}

.wfi-color-2 {
  color: rgba(9, 89, 174, 0.1);
}
