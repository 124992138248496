.wfi-button {
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
}

.wfi-button-1 {
  background-color: var(--color1);
  border-color: var(--color1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.wfi-button-1:hover {
  background-color: var(--color1);
  border-color: var(--color1);
  box-shadow: none;
  color: #eee;
}

.wfi-button-2 {
  background-color: var(--color2);
  border-color: var(--color2);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.wfi-button-2:hover {
  background-color: var(--color2);
  border-color: var(--color2);
  box-shadow: none;
  color: #eee;
}

.wfi-button-3 {
  background-color: var(--color3);
  border-color: var(--color3);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.wfi-button-3:hover {
  background-color: var(--color3);
  border-color: var(--color3);
  box-shadow: none;
  color: #eee;
}

.wfi-button-4 {
  background-color: #fff;
  border: none;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  color: #191919 !important;
}

.wfi-button-4:hover {
  background-color: #fff;
  border: none;
  text-decoration: underline;
}

.outline-btn {
  cursor: pointer;
  background: none;
  border: 1.5px solid #054266;
  border-radius: 0.25rem;
  color: #054266;
  font-weight: 600;
  padding: 6px 10px;
  text-transform: uppercase;
  transition: all 250ms ease-in-out;
}

.outline-btn:hover {
  background: rgba(7, 87, 135, 1);
  /* border: none; */
  color: white;
}
