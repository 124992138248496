body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 0.6em;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: var(--color1);
}

button,
.btn {
  border-radius: 3px;
}

.btn-solid-hover {
  background: #0077b4;
  transition: background 350ms ease-out;
  cursor: pointer;
}

.btn-solid-hover:hover {
  background: #006496;
}

.chevron-sidebars {
  width: 40px;
  top: 0;
  bottom: 0;
  width: 60px;
  margin: auto;
  z-index: 1;
}

.chevron-sidebars:hover {
  cursor: pointer;
}

.chevron-sidebars svg {
  margin: auto;
  height: 100%;
}

.chevron-sidebars.left {
  left: 0;
  text-align: left;
}

.chevron-sidebars.right {
  right: 0;
  text-align: right;
}

.blue-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(9, 11, 73, 0.5);
  z-index: -1;
}

.dark-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.28);
  z-index: -1;
}

.dropdown-toggle::after {
  margin-left: 0.5em;
  vertical-align: 0;
}

.flex-between {
  justify-content: space-between;
}

.flex-column {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.hero {
  min-height: 400px;
  padding: 150px 100px;
  color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 18;
}

.hero .parts {
  position: absolute;
  display: grid;
  max-width: 1200px;
  margin: auto;
  right: 50px;
  left: 50px;
  z-index: 20;
}

.hero .parts.single {
  grid-template-columns: 1fr;
}

.hero .parts.double {
  grid-template-columns: 1fr 1fr;
}

.line-break {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1px;
}

.noborder {
  border: none;
}

.nowrap {
  white-space: nowrap;
}

.opaque-background {
  background-color: #eee;
}

.shadow1 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow2 {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05), 0 8px 8px rgba(0, 0, 0, 0.12);
}

.shadow3 {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);
}

.shadow4 {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02), 0 6px 6px rgba(0, 0, 0, 0.03);
}

.shadow5 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.02), 0 6px 6px rgba(0, 0, 0, 0.03);
}

.standard-apply-button {
  text-align: center;
  display: inline-block;
  background: rgb(14, 130, 198);
  background: linear-gradient(
    90deg,
    rgba(14, 130, 198, 1) 0%,
    rgba(7, 87, 135, 1) 100%
  );
  padding: 10px 15px;
  max-height: 60px;
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 350ms ease-in-out;
  width: 60%;
  margin-left: 20%;
}

.standard-apply-button:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);
}

.standard-page-head {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 400px;
  padding: 150px 10px;
  background: rgb(29, 29, 51);
  color: white;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.standard-page-head h1 {
  margin-bottom: 2px;
}

.standard-page-head .getting-started-button {
  display: inline-block;
  text-transform: uppercase;
  padding: 10px 30px;
  border: 1.5px solid white;
  margin: auto;
  margin-top: 20px;
  font-weight: 600;
  transition: all 350ms ease-in-out;
  cursor: pointer;
  background-color: transparent;
}

.standard-page-head .getting-started-button:hover {
  background: white;
  color: rgb(29, 29, 51);
  font-weight: 600;
}

.standard-page-head p {
  margin-top: 1rem;
  margin-bottom: 0px;
}

.text-bold {
  font-weight: 600;
}

.wfi-grid {
  display: grid;
}

.wfi-section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.white-background {
  background: white;
}

@media screen and (max-width: 768px) {
  .standard-apply-button {
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 568px) {
  .fb-customerchat {
    bottom: 70px;
  }

  .hero {
    min-height: 50vh;
  }
}
